import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { isUserLoggedIn } from './utils/basicReq'

export default function OnlyPublicRoutes({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return isUserLoggedIn() ? <Navigate to="/couponstab" /> : children
}
